import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from "@elastic/eui";
import NavButtons from "components/Software/Linux/NavButtons";
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
export const _frontmatter = {
  "title": "iSpy Agent DVR in Docker",
  "path": "/Software/Linux/iSpy_DVR_Agent/",
  "dateChanged": "2023-02-06",
  "author": "Mike Polinowski",
  "excerpt": "Agent DVR is a standalone replacement for iSpy that runs as a service, uses less CPU.",
  "image": "./P_SearchThumb_Linux.png",
  "social": "/images/Search/P_SearchThumb_Linux.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Linux_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "iSpy"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title="iSpy Agent DVR in Docker" dateChanged="2023-02-06" author="Mike Polinowski" tag="INSTAR IP Camera" description="Agent DVR is a standalone replacement for iSpy that runs as a service, uses less CPU." image="/images/Search/P_SearchThumb_Linux.png" twitter="/images/Search/P_SearchThumb_Linux.webp" location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE="/de/Software/Linux/iSpy_DVR_Agent/" locationFR="/fr/Software/Linux/iSpy_DVR_Agent/" crumbLabel="iSpy" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "ispy-agent-dvr-in-docker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#ispy-agent-dvr-in-docker",
        "aria-label": "ispy agent dvr in docker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`iSpy Agent DVR in Docker`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#ispy-agent-dvr-in-docker"
        }}>{`iSpy Agent DVR in Docker`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#setup"
            }}>{`Setup`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#adding-your-instar-2k-wqhd-camera"
            }}>{`Adding your INSTAR 2k+ WQHD Camera`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#adding-mqtt-events"
                }}>{`Adding MQTT Events`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <p><a parentName="p" {...{
        "href": "https://www.ispyconnect.com/userguide-agent-DVR.aspx"
      }}>{`Agent DVR`}</a>{` - Agent DVR is a standalone replacement for iSpy that runs as a service, uses less CPU and features a web user interface that is accessible from anywhere with no port forwarding required!`}</p>
    <p>{`Agent runs it's own webserver. By default this is running at http://localhost:8090 on the PC running Agent (although it may be using a different port if that wasn't available or was changed when it was installed). Agent will also work over your local network - you can use private IP addresses or computer names to access Agent locally (examples: `}<a parentName="p" {...{
        "href": "http://192.168.1.10:8090"
      }}>{`http://192.168.1.10:8090`}</a>{` or http://nvr:8090). You may need to open your firewall to Agent for your local network.`}</p>
    <h2 {...{
      "id": "setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setup",
        "aria-label": "setup permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setup`}</h2>
    <p>{`You need to have `}<a parentName="p" {...{
        "href": "https://docs.docker.com/get-docker/"
      }}>{`Docker installed`}</a>{` before continue with this guide.`}</p>
    <p>{`You can build the image by `}<a parentName="p" {...{
        "href": "https://github.com/mpolinowski/agent-dvr-docker"
      }}>{`cloning the repository`}</a>{` and running the following command from inside the directory:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` clone https://github.com/mpolinowski/agent-dvr-docker.git `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` agent-dvr-docker
docker build -t agent-dvr `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span></code></pre></div>
    <p>{`To persist data generated inside the running container later we have to create 3 directories on the host system (you can choose the location at will - but you will need to adjust the RUN command below accordingly):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p /opt/dvr-agent/`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`config,media,commands`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`chown`}</span>{` -R myuser /opt/dvr-agent/*`}</code></pre></div>
    <p>{`You need to expose the port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8090`}</code>{` for the web user interface. The ports `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`3478`}</code>{` (`}<strong parentName="p">{`STUN`}</strong>{`) and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`50000`}</code>{` - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`50010`}</code>{`  (`}<strong parentName="p">{`TURN`}</strong>{`) are needed for the webRTC video connection:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run -it -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8090`}</span>{`:8090 -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3478`}</span>{`:3478/udp -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`50000`}</span>{`-50010:50000-50010/udp `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-v /opt/dvr-agent/config/:/agent/Media/XML/ `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-v /opt/dvr-agent/media/:/agent/Media/WebServerRoot/Media/ `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-v /opt/dvr-agent/commands:/agent/Commands/ `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-e `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`TZ`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`Europe/Berlin `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
--name agent-dvr agent-dvr:latest`}</code></pre></div>
    <p>{`This command runs the container process attached to your terminal and allows you to spot eventual error messages. To run the container in the background and make sure that it is restarted automatically if needed use the following command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run --restart`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`unless-stopped -d -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8090`}</span>{`:8090 -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3478`}</span>{`:3478/udp -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`50000`}</span>{`-50010:50000-50010/udp `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-v /opt/dvr-agent/config/:/agent/Media/XML/ `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-v /opt/dvr-agent/media/:/agent/Media/WebServerRoot/Media/ `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-v /opt/dvr-agent/commands:/agent/Commands/ `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-e `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`TZ`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`Europe/Berlin `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
--name agent-dvr agent-dvr:latest`}</code></pre></div>
    <p>{`You can kill the container with the following command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rm`}</span>{` -f agent-dvr`}</code></pre></div>
    <p>{`You can visit the webUI in your browser on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://localhost:8090`}</code>{` and allow Agent to access your media devices:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b29c9f2c2c997c695d5e41f7f30ce503/f663d/iSpy_DVR_Agent_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAAA7klEQVQY03WP607DMAyF+yKQi50SO23TpC3qNgHT1Em02o8Jsfd/FQhlVdnEkaUk9vl0nKwqO8exKrthOO3f3jf9frs9hPbFujY3hUG3LgRmqgvX1P65bXYZEVlLRVEeh/M0fk7jZZwuH7vjFDchNszupoiYiJhdjE2mtVJKAYD3be0777vSNweuXs3ToxTqfwkhMwDQP5JSyCQhpXhQUgAg4jxaPPMd0ih1Mq3/9BETAwAmHXhDaq2NMXNHqSu8OFaLSCHS2vf8HP6bvDwWISIzhxCI6J6cv5OS4aq1w1obY+z7PoSQ5/liWNu+4S/YiElzVbh3SAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b29c9f2c2c997c695d5e41f7f30ce503/e4706/iSpy_DVR_Agent_01.avif 230w", "/en/static/b29c9f2c2c997c695d5e41f7f30ce503/d1af7/iSpy_DVR_Agent_01.avif 460w", "/en/static/b29c9f2c2c997c695d5e41f7f30ce503/7f308/iSpy_DVR_Agent_01.avif 920w", "/en/static/b29c9f2c2c997c695d5e41f7f30ce503/e1c99/iSpy_DVR_Agent_01.avif 1380w", "/en/static/b29c9f2c2c997c695d5e41f7f30ce503/6e2a7/iSpy_DVR_Agent_01.avif 1511w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b29c9f2c2c997c695d5e41f7f30ce503/a0b58/iSpy_DVR_Agent_01.webp 230w", "/en/static/b29c9f2c2c997c695d5e41f7f30ce503/bc10c/iSpy_DVR_Agent_01.webp 460w", "/en/static/b29c9f2c2c997c695d5e41f7f30ce503/966d8/iSpy_DVR_Agent_01.webp 920w", "/en/static/b29c9f2c2c997c695d5e41f7f30ce503/445df/iSpy_DVR_Agent_01.webp 1380w", "/en/static/b29c9f2c2c997c695d5e41f7f30ce503/fdb89/iSpy_DVR_Agent_01.webp 1511w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b29c9f2c2c997c695d5e41f7f30ce503/81c8e/iSpy_DVR_Agent_01.png 230w", "/en/static/b29c9f2c2c997c695d5e41f7f30ce503/08a84/iSpy_DVR_Agent_01.png 460w", "/en/static/b29c9f2c2c997c695d5e41f7f30ce503/c0255/iSpy_DVR_Agent_01.png 920w", "/en/static/b29c9f2c2c997c695d5e41f7f30ce503/b1001/iSpy_DVR_Agent_01.png 1380w", "/en/static/b29c9f2c2c997c695d5e41f7f30ce503/f663d/iSpy_DVR_Agent_01.png 1511w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b29c9f2c2c997c695d5e41f7f30ce503/c0255/iSpy_DVR_Agent_01.png",
              "alt": "iSpy Agent DVR in Docker",
              "title": "iSpy Agent DVR in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "adding-your-instar-2k-wqhd-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-your-instar-2k-wqhd-camera",
        "aria-label": "adding your instar 2k wqhd camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding your INSTAR 2k+ WQHD Camera`}</h2>
    <p>{`Open the `}<strong parentName="p">{`Server Menu`}</strong>{` and click to add a new device:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/aea3877c6cc8e34d3c9360f6c4562d29/e088a/iSpy_DVR_Agent_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACY0lEQVQoz33S20sUURzA8bNzZnS9IbG4TrvnzJzbzOyM7oa77LpatG1lmpoRVigoKbl5Cdd7WCFthCFYgSQFFoZBD/aULz1YoSC99BAU9BoEEUT/RMzkgyH1OT/4PX3hwDkgqBQxv1/VNMM0MdYRxlXB6ipPOBxWVbVqn0AgUOwBFRAeACDdUH/+bFtjKnG4PhFzTNMQ7uFcCGEYhiG88ZZlmRgjnwdAWYayooVDnCBGsMF0zgkmRKOUcE4p1QmhlDLGCCG6rlNKQ6EQ9ABZliGUHKcmk8nG4wmNkHQsdu5YJm1HHITq6uLZbLa2tlbX9WQymXEdFUJIkuTGRYoMAMw10Q+37J3J4GpXcU9Hb67wvPvGakfnYF9y8G7LeuHIWrc13ZkX0yv21UfVJy5VAKDIym6sjDVVf5tHX+fKNy+DTM94+/J22/J2fPzBQGr2ccvWUuPbK+ZC16x2/YWeXys6PezfG0sjLezLvPNpuuRVr5ToHWt4+C56f1PLL/anppZaXy+kNvrF7a4Za2aVDa/4Tw2UAiBDWQKKDAGQJtvJz3v0e6F8Z7TctCxmx7gT5RHHZBGbRWtEjKNI31zk5ks8tV7aNlq8N/YNNbPPd5yP1wIbuUqDEUoJc4dSRikjjBOMyIUJbfxpaOJZsDlXtjeGU63o1yL+USh7P+o3BKecsz/jLcG5jtnFCZJfCY48KW3O/XVtX99xujVrvRkqWeupFJy5z7qPaRMrqps2RTgkeQCE7jfBWKdcECYoE/wfGBNEE3VpFjl00AfgbgwhVFUVY4z+C2tIVVHjmWD8ZCVwO+k34wmQh6R3uGcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aea3877c6cc8e34d3c9360f6c4562d29/e4706/iSpy_DVR_Agent_02.avif 230w", "/en/static/aea3877c6cc8e34d3c9360f6c4562d29/d1af7/iSpy_DVR_Agent_02.avif 460w", "/en/static/aea3877c6cc8e34d3c9360f6c4562d29/7f308/iSpy_DVR_Agent_02.avif 920w", "/en/static/aea3877c6cc8e34d3c9360f6c4562d29/9e7d9/iSpy_DVR_Agent_02.avif 1015w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/aea3877c6cc8e34d3c9360f6c4562d29/a0b58/iSpy_DVR_Agent_02.webp 230w", "/en/static/aea3877c6cc8e34d3c9360f6c4562d29/bc10c/iSpy_DVR_Agent_02.webp 460w", "/en/static/aea3877c6cc8e34d3c9360f6c4562d29/966d8/iSpy_DVR_Agent_02.webp 920w", "/en/static/aea3877c6cc8e34d3c9360f6c4562d29/3a6ad/iSpy_DVR_Agent_02.webp 1015w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aea3877c6cc8e34d3c9360f6c4562d29/81c8e/iSpy_DVR_Agent_02.png 230w", "/en/static/aea3877c6cc8e34d3c9360f6c4562d29/08a84/iSpy_DVR_Agent_02.png 460w", "/en/static/aea3877c6cc8e34d3c9360f6c4562d29/c0255/iSpy_DVR_Agent_02.png 920w", "/en/static/aea3877c6cc8e34d3c9360f6c4562d29/e088a/iSpy_DVR_Agent_02.png 1015w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/aea3877c6cc8e34d3c9360f6c4562d29/c0255/iSpy_DVR_Agent_02.png",
              "alt": "iSpy Agent DVR in Docker",
              "title": "iSpy Agent DVR in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select a display slot:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e84d2af8df0d6e0a2411eefd1814da4c/73b94/iSpy_DVR_Agent_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.956521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAzElEQVQY033NzWqDQBiF4flmDF5AiHZ0kRB1tIm20ETjiEJSKF3oTOeHIOQucv+bINJFoe3h2b4c5C4Wruuu1xuWsGi7jaOYMRYGobfywiCklPqe/+3JXy5pktPj+1whTAjBpHgpmq4pT8eKV7yt89c83aXZPvvpOc3iXXdOPzRGU4gIIQBw4AdpZS/6Xk6EElLLX3yJ++el428IgePMMYK6rcdxNNpYY62x5q8pfbteTx0HBGR+xoDLplRWDWIQUvxjEIOyumoqgCl+AE5YOjfnNAErAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e84d2af8df0d6e0a2411eefd1814da4c/e4706/iSpy_DVR_Agent_03.avif 230w", "/en/static/e84d2af8df0d6e0a2411eefd1814da4c/d1af7/iSpy_DVR_Agent_03.avif 460w", "/en/static/e84d2af8df0d6e0a2411eefd1814da4c/7f308/iSpy_DVR_Agent_03.avif 920w", "/en/static/e84d2af8df0d6e0a2411eefd1814da4c/f13f3/iSpy_DVR_Agent_03.avif 1004w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e84d2af8df0d6e0a2411eefd1814da4c/a0b58/iSpy_DVR_Agent_03.webp 230w", "/en/static/e84d2af8df0d6e0a2411eefd1814da4c/bc10c/iSpy_DVR_Agent_03.webp 460w", "/en/static/e84d2af8df0d6e0a2411eefd1814da4c/966d8/iSpy_DVR_Agent_03.webp 920w", "/en/static/e84d2af8df0d6e0a2411eefd1814da4c/22cc9/iSpy_DVR_Agent_03.webp 1004w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e84d2af8df0d6e0a2411eefd1814da4c/81c8e/iSpy_DVR_Agent_03.png 230w", "/en/static/e84d2af8df0d6e0a2411eefd1814da4c/08a84/iSpy_DVR_Agent_03.png 460w", "/en/static/e84d2af8df0d6e0a2411eefd1814da4c/c0255/iSpy_DVR_Agent_03.png 920w", "/en/static/e84d2af8df0d6e0a2411eefd1814da4c/73b94/iSpy_DVR_Agent_03.png 1004w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e84d2af8df0d6e0a2411eefd1814da4c/c0255/iSpy_DVR_Agent_03.png",
              "alt": "iSpy Agent DVR in Docker",
              "title": "iSpy Agent DVR in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here you can add a new device - choose to add a new `}<strong parentName="p">{`Video Source`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/64320459942c4a388a237063791c0eac/d6a46/iSpy_DVR_Agent_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABOElEQVQoz2NgZ2VhZ2dnZWVlgQFmZmY5eXkdHV0dXV1tHV1tHR1ZWVlkBaysrBwcHGxsbAzMLCxMTEzMSICRkVFfV9fDxcnextrJ3sbHw1VPW5OFhRVZDRMYMECsQgAWFiYGBhWXYP2iibrZHZqZHUpZPUrBmWysLKhWgACmZlZGBgZd/3jb9lVaZbM0y2Zp1SxSiykHaWYmQjMTA4NCSJbe5IMqrRtU2zaq9exSzOxgYyFac7h7WHr1vPDcCSE5/aFFM0ICMrmYmRmZiXO2i3eEf81c5/x+65w+m8JpViHZHCzMTERqVg9KMe3Zot+wVLV2iVrzWsWkeuL8zMzMxMigrGuk5+Knae+pauOh5uCtYmbDxsbKhBHcWDQzs7DISEooykgqykrKikmb24t4JQiysLAxYzgcAMj+PR9YGmCRAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/64320459942c4a388a237063791c0eac/e4706/iSpy_DVR_Agent_04.avif 230w", "/en/static/64320459942c4a388a237063791c0eac/d1af7/iSpy_DVR_Agent_04.avif 460w", "/en/static/64320459942c4a388a237063791c0eac/7f308/iSpy_DVR_Agent_04.avif 920w", "/en/static/64320459942c4a388a237063791c0eac/86669/iSpy_DVR_Agent_04.avif 1008w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/64320459942c4a388a237063791c0eac/a0b58/iSpy_DVR_Agent_04.webp 230w", "/en/static/64320459942c4a388a237063791c0eac/bc10c/iSpy_DVR_Agent_04.webp 460w", "/en/static/64320459942c4a388a237063791c0eac/966d8/iSpy_DVR_Agent_04.webp 920w", "/en/static/64320459942c4a388a237063791c0eac/6e1e4/iSpy_DVR_Agent_04.webp 1008w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/64320459942c4a388a237063791c0eac/81c8e/iSpy_DVR_Agent_04.png 230w", "/en/static/64320459942c4a388a237063791c0eac/08a84/iSpy_DVR_Agent_04.png 460w", "/en/static/64320459942c4a388a237063791c0eac/c0255/iSpy_DVR_Agent_04.png 920w", "/en/static/64320459942c4a388a237063791c0eac/d6a46/iSpy_DVR_Agent_04.png 1008w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/64320459942c4a388a237063791c0eac/c0255/iSpy_DVR_Agent_04.png",
              "alt": "iSpy Agent DVR in Docker",
              "title": "iSpy Agent DVR in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Configure the video source according to your needs:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fd6832c4bd31c09424ccf90dfd36cae9/00d43/iSpy_DVR_Agent_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABOUlEQVQoz43Sy0vDQBAG8MlmUzCppXnVplmCiclmd5O2xLYUtC1iH6RY8YUnwasXD4p68+xFEMF/WKooSkXynefHxwwDWrmsKIr8FYRQpVKx7JpdqxFCDF2vVqtrqooQ+p4plUqqqmqaBvKPYIwBQDB6lE8W83w+n49GwzAMLcuSJAljLP/OLyxjLAG4IssubtLhNOEsojQMQ9M0i2LS6veun8TegYgjxnjMmGEYRbAiAXjbO4P7l/bsOGE0jhmltGgzAnCDqD1eiE6/maacC8ZYQbxsJtmg//DWnJ22Es5FUnznT7zbvXtNpydNTmlMKY0KH0wC4m9193OR9UggXJ/7XqzrSyzL/+OPJ3HqG4EfdLh3m7tXE2d8RhqbOoC0UvwXti3Lcb1OXH8+dx4P7fzSbATrAGgVvwMldS0dBD4ooAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fd6832c4bd31c09424ccf90dfd36cae9/e4706/iSpy_DVR_Agent_05.avif 230w", "/en/static/fd6832c4bd31c09424ccf90dfd36cae9/d1af7/iSpy_DVR_Agent_05.avif 460w", "/en/static/fd6832c4bd31c09424ccf90dfd36cae9/7f308/iSpy_DVR_Agent_05.avif 920w", "/en/static/fd6832c4bd31c09424ccf90dfd36cae9/445fc/iSpy_DVR_Agent_05.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fd6832c4bd31c09424ccf90dfd36cae9/a0b58/iSpy_DVR_Agent_05.webp 230w", "/en/static/fd6832c4bd31c09424ccf90dfd36cae9/bc10c/iSpy_DVR_Agent_05.webp 460w", "/en/static/fd6832c4bd31c09424ccf90dfd36cae9/966d8/iSpy_DVR_Agent_05.webp 920w", "/en/static/fd6832c4bd31c09424ccf90dfd36cae9/a5d4d/iSpy_DVR_Agent_05.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fd6832c4bd31c09424ccf90dfd36cae9/81c8e/iSpy_DVR_Agent_05.png 230w", "/en/static/fd6832c4bd31c09424ccf90dfd36cae9/08a84/iSpy_DVR_Agent_05.png 460w", "/en/static/fd6832c4bd31c09424ccf90dfd36cae9/c0255/iSpy_DVR_Agent_05.png 920w", "/en/static/fd6832c4bd31c09424ccf90dfd36cae9/00d43/iSpy_DVR_Agent_05.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fd6832c4bd31c09424ccf90dfd36cae9/c0255/iSpy_DVR_Agent_05.png",
              "alt": "iSpy Agent DVR in Docker",
              "title": "iSpy Agent DVR in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Give your video source a name and assign it to a `}<strong parentName="p">{`Location`}</strong>{` and `}<strong parentName="p">{`Group`}</strong>{`. As `}<strong parentName="p">{`Source Type`}</strong>{` select an `}<strong parentName="p">{`IP Camera`}</strong>{` and click the `}<em parentName="p">{`three dots`}</em>{` to configure it:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6c359998fde319a69a90f191e788741f/2c5fd/iSpy_DVR_Agent_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB1UlEQVQoz23PwW/SUAAG8EdbbtiuwCjQAn3vsaIQkBjCohgue68rGhObYUJCooddPBkPsmIgnjwsMXJpYmvp8ILbYkt28Q801LGA8Xd4ee/w5X0f4HleEIS9yN1dEIRkMinLsiiKexuZVDaXktP7spBMJRIJnucBwzD5fL59eNhqtTRNKxaL2WxWiuxvSafTLw/evtfOn/Q+p9pdFgCWi4MYE5MkqVwuY4whhJTS2Wz2dW1m27bv+/MN37uYu/7lz2tKdQBAPL4VRghBCJvN5nA4HAwG/X7fNM3+lpP+iWmab06HDx/VAQAcx+2EVVXtdDpno9F0Op1MJuPxeGxZVnRYlnU2sj5+sl5336F7dcABjuV2apdKJUqp4zi+7/sXa4vFwvM8N+I47nff+fLhW+vgafTz7maMMaXUtm3P8+b/4zrur5tr4/nxv5shhOoarFQqCEFFUZRCQdkly0oJFjM5KcYwLMvehvFGo9EghOq6bvR65NkLctwj5Ij8dUSITrptomVhnAHs3WaMsaZpGGPDMJbL5dXlVRiGQRAEYRiEwWq1Ctfv8OZ3eH7645X4WGQBw+7WhhAihOr1eq1Wu//gVrVaxRirqorQeldRLUi5DBPV/gPOo6EfYvGkUAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6c359998fde319a69a90f191e788741f/e4706/iSpy_DVR_Agent_06.avif 230w", "/en/static/6c359998fde319a69a90f191e788741f/d1af7/iSpy_DVR_Agent_06.avif 460w", "/en/static/6c359998fde319a69a90f191e788741f/7f308/iSpy_DVR_Agent_06.avif 920w", "/en/static/6c359998fde319a69a90f191e788741f/f287e/iSpy_DVR_Agent_06.avif 1006w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6c359998fde319a69a90f191e788741f/a0b58/iSpy_DVR_Agent_06.webp 230w", "/en/static/6c359998fde319a69a90f191e788741f/bc10c/iSpy_DVR_Agent_06.webp 460w", "/en/static/6c359998fde319a69a90f191e788741f/966d8/iSpy_DVR_Agent_06.webp 920w", "/en/static/6c359998fde319a69a90f191e788741f/1e748/iSpy_DVR_Agent_06.webp 1006w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6c359998fde319a69a90f191e788741f/81c8e/iSpy_DVR_Agent_06.png 230w", "/en/static/6c359998fde319a69a90f191e788741f/08a84/iSpy_DVR_Agent_06.png 460w", "/en/static/6c359998fde319a69a90f191e788741f/c0255/iSpy_DVR_Agent_06.png 920w", "/en/static/6c359998fde319a69a90f191e788741f/2c5fd/iSpy_DVR_Agent_06.png 1006w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6c359998fde319a69a90f191e788741f/c0255/iSpy_DVR_Agent_06.png",
              "alt": "iSpy Agent DVR in Docker",
              "title": "iSpy Agent DVR in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The DVR Agents needs your `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/System/User/"
      }}>{`camera login`}</a>{` as well as the RTSP URL of your camera:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-5907_HD/Video_Streaming/"
        }}>{`HD Cameras`}</a>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`rtsp://user:password@192.168.x.x:/[11-13]`}</code></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-9020_HD/Video_Streaming/"
        }}>{`Full HD Cameras`}</a>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`rtsp://user:password@192.168.x.x:/[11-13]`}</code></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-5907_HD/Video_Streaming/"
        }}>{`WQHD Cameras`}</a>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`rtsp://user:password@192.168.x.x:/livestream[11-13]`}</code></li>
    </ul>
    {/* /Web_User_Interface/1440p_Serie/System/Benutzer/
     /Aussenkameras/IN-9408_WQHD/Video_Streaming/
     /Aussenkameras/IN-9408_WQHD/Video_Streaming/
     /Aussenkameras/IN-5907_HD/Video_Streaming/
     */}
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/55dc563cd76b3787e8c2db8032985c9a/2c5fd/iSpy_DVR_Agent_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABvElEQVQoz3XRPW/TQByA8bPPGaB2fLipHUfE9p1fAiEpkLSOlTKEiiFjInVD7O2AYGNgoJWIc1VVEBWiqZRECTMSC0OQkHGcsPCpkK9FrXj5DXd/nfTopDsgiqKMkMLIsiwxoigihPL5fDabPT+QJWQoroW8nO6Kq7mV69ckSQIAgGKx2GxuBY2g5HmEEI9xGTaWHM9eL9WebR298I8fPT5eu+9zAEBBSGPDMBqNYKNe930fY6zrev5fVE1VtXRRbig8z0MIAcdxhUKhWq3WarVKpdJqtQ4O9sMw7P+FUhqG4cng3WawAQDIZDKA53lNVTHGrutijDudThzHSZIsFovlcplcMZ8nyY/5pzffNr0HaSycx5rmOI5t25ZldbvdKIq+MrPZLIoilqXi7/HyZ/L+yedbkg8gEOCV2GOvtb39kFLa6/VeM/0+PfyN9unR28PnnVe2cgfwQIDCZVwulwkh7XZ7PB6fpQaD09PJZDKdTofD4Wg0+jAYnX0cv9w5cdHdP2/GF8jFxmCMLdM0TBNbRrNqBhVjvW7qN3Mc4KEAL2PyH7ZNLOLcu23Nnmpf9tTd/dVSA6XfnIG/ACgrme2PB13GAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/55dc563cd76b3787e8c2db8032985c9a/e4706/iSpy_DVR_Agent_07.avif 230w", "/en/static/55dc563cd76b3787e8c2db8032985c9a/d1af7/iSpy_DVR_Agent_07.avif 460w", "/en/static/55dc563cd76b3787e8c2db8032985c9a/7f308/iSpy_DVR_Agent_07.avif 920w", "/en/static/55dc563cd76b3787e8c2db8032985c9a/f287e/iSpy_DVR_Agent_07.avif 1006w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/55dc563cd76b3787e8c2db8032985c9a/a0b58/iSpy_DVR_Agent_07.webp 230w", "/en/static/55dc563cd76b3787e8c2db8032985c9a/bc10c/iSpy_DVR_Agent_07.webp 460w", "/en/static/55dc563cd76b3787e8c2db8032985c9a/966d8/iSpy_DVR_Agent_07.webp 920w", "/en/static/55dc563cd76b3787e8c2db8032985c9a/1e748/iSpy_DVR_Agent_07.webp 1006w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/55dc563cd76b3787e8c2db8032985c9a/81c8e/iSpy_DVR_Agent_07.png 230w", "/en/static/55dc563cd76b3787e8c2db8032985c9a/08a84/iSpy_DVR_Agent_07.png 460w", "/en/static/55dc563cd76b3787e8c2db8032985c9a/c0255/iSpy_DVR_Agent_07.png 920w", "/en/static/55dc563cd76b3787e8c2db8032985c9a/2c5fd/iSpy_DVR_Agent_07.png 1006w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/55dc563cd76b3787e8c2db8032985c9a/c0255/iSpy_DVR_Agent_07.png",
              "alt": "iSpy Agent DVR in Docker",
              "title": "iSpy Agent DVR in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Configure the video source according to your needs:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c1a24f32498da8c0da584a0890e6f227/2c5fd/iSpy_DVR_Agent_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "82.6086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7rAAAO6wFxzYGVAAADAklEQVQ4y22S30/bVhTHT5qYkBScX8QmsWNf+17HScjKz6BCSxUagjoxCoP2tXuZNKAg1KIGwRBakFpoEFIdBxoeAk34IbVS+1S1Y2KgVtoTUvcvTXZYCRIffa917sNH59g+AAAMwxJCMCYISX19fSsrK88vk81m/zBZXl5eW1vr6ekBAIqiwOl0BINBjHE4rBJCUqmUruvFYnF7u5rtra2tfD5fKBR0Xdc0rVQqJZNJAKij7OBzcyEeYSxHo1FZltPp9HaxuLOzU6lU9i6olM17uVx+9+5tOnXPAtcb7QxYgGb9HMYYISQhJIiiJMsIIUEQeJ73eDxer8dbPSY+n9fva75O+RuoJrCBu5nhFYVE1AjG+FZv7+/zz5YWF5eWlhYWFjKZ+e9kTObm5jrbu+uAbqzzGzJryrFYjGCcTA3kXpfWtcJ6Lrf28uWLy6yurmaz2ZuJXsqQGbCanY2xJQmFONzRE59aVQbGEB/k+BpCfCAQYFmW47kAwzXYmqqyx5TlsKpGw4Qkbivdd2SBl7GBJEliFSTyPM9x3FUywYQoBOObd/p/m306/fjx9PTMzMzM1NTUxP9MTk5OTEzMzs52Ge/sou3suWzsiDE5Gv5p6OjPzyc1nNZwfHx8dnY2NvIAwOF2BMB20ZlIknR/ZOTk5OTrly9fr+L09PTbv9/GRx9ekqudRVHs7+/f2NjIa1reQNPN53mhaeu53OZmIdl31waNxtg28FY/mKqqiqIMDqZLO7uVvf29/YPa7B8c7h8clt9U3n/48OPgkBUaXPUBsIKzmTF2W1WN3R79efTF7t+ZzaPFraOF139VM1/4nNE/ZfRPT159zB3+M3x/HMDitLvBYgE/y8iEyJKEEFLVSFtHorW9y0hHotWs4zfaW35oU1vawrHW+I1Ong+BBa5ZLQCUY6DJPx0KBQlRqj9WCNUGiSFBECIyV3rkP/yF+fWpO37bZwVHvb0eXLEuMdYdjSeYSBtNu2iadrncV6SR7myhe9u98YSniXXZqXqnw/kfM8QlD6cjq68AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c1a24f32498da8c0da584a0890e6f227/e4706/iSpy_DVR_Agent_08.avif 230w", "/en/static/c1a24f32498da8c0da584a0890e6f227/d1af7/iSpy_DVR_Agent_08.avif 460w", "/en/static/c1a24f32498da8c0da584a0890e6f227/7f308/iSpy_DVR_Agent_08.avif 920w", "/en/static/c1a24f32498da8c0da584a0890e6f227/f287e/iSpy_DVR_Agent_08.avif 1006w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c1a24f32498da8c0da584a0890e6f227/a0b58/iSpy_DVR_Agent_08.webp 230w", "/en/static/c1a24f32498da8c0da584a0890e6f227/bc10c/iSpy_DVR_Agent_08.webp 460w", "/en/static/c1a24f32498da8c0da584a0890e6f227/966d8/iSpy_DVR_Agent_08.webp 920w", "/en/static/c1a24f32498da8c0da584a0890e6f227/1e748/iSpy_DVR_Agent_08.webp 1006w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c1a24f32498da8c0da584a0890e6f227/81c8e/iSpy_DVR_Agent_08.png 230w", "/en/static/c1a24f32498da8c0da584a0890e6f227/08a84/iSpy_DVR_Agent_08.png 460w", "/en/static/c1a24f32498da8c0da584a0890e6f227/c0255/iSpy_DVR_Agent_08.png 920w", "/en/static/c1a24f32498da8c0da584a0890e6f227/2c5fd/iSpy_DVR_Agent_08.png 1006w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c1a24f32498da8c0da584a0890e6f227/c0255/iSpy_DVR_Agent_08.png",
              "alt": "iSpy Agent DVR in Docker",
              "title": "iSpy Agent DVR in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Close the configuration menu and check if the video stream was added:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/854f9cc5a6ccea7d0e26aa41f98ff8d2/6bfd0/iSpy_DVR_Agent_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "88.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAEkklEQVQ4y5WUaUyUBxCGP9j92AMLgbaIbdrEVCHtH02bRlPT4B1FLHggggsFFPCoKKCwEhTEA8+ggqICLgiVoyLIraiccpSKoOKK7soCwnJDrdWaNnkatrZJY9KkP57MkbwzmUzyCu9bWTHZ3h65XI6FhcW/kMlkSKVS7OzssLW1RRRFU1+hVDJ12jQ+cXRkqoMDUx0dmbPkGz6dORNBKXsHqVRiEr+FXP5m0ZtBCoVpwZTJdlxKTKAoPY38pDNcPnWSyyfiOb73IIJMIkcQhP/Fu4JAur8vlWUFVDs4oLe2Qj/JklvW1giLF7qwbPk3rHJfg+c6L7y9VXipvFnp5YNqfQAbt21jR6SayOgo9sRGs3tfDDGREWTERJMcd5DEVWtIWLSUo1/PI+LzWQievlvwDw4lJCqGmKPxnEhJ5WJeLiU3S6lpqqSxpZaG1jpqmysprSwi62o2JzXJhMUdxidEjUvQdpxUgcx08cBxrjPCrHluzHX1ZOna9ai+CyPycBzHzieRXniFnJtl5N0opqG5gvrmCmoayrheVUBuYQanUxNR74tFFbiVJSvXMWf+MpwWuiAsdPFkxbdBROw/yJn0ZMor8/mhrJTU6mYytVquadsZGTIwMNRFr1GHofsh7Y9+pKq+hIKyHDTZaZxKPofv5q0scl2DsNovhC3qKFIvXeR6dQl37982iYaHuxkf62V0pJvOLi2P9W309esZGX3GwKCBzu6H3LlXR0VNGRdyMwkI3cniFZ4IfkFhuHn5sNbXj117wsm7kkLLnWIaGwu4317L4IiB8luF5ORn0tOvp8eoQ294QFt7PflFGpJTD7Eregc+GzcRFBKGEBi4mZDwUCKjw9GkHaehqdQkePL0AbouLd36RwwOdDMyZsTQ85iq+us03a1Fp7tHXX05La2VXMo9S3h0JMFqNcJKrw1sCt3GgSMxZOcmUXN74uwmOvTt6AwdtLY2Mjzcx4uX4/QbO03n9/R2UNN6m6LqUqpqS0i5eJpgdTjuvgEIzqv9CAgJIz4pnuLyHFraakyiXmMnQyN9/PpqnN7+Lp481TI2auT3P17x+vUvPNS10dBShfZxM3nFWcQei2Nd4CaE5R7rCdoRwZHT8Vwtz6X1fh1dPVr6jHrTZ8fGBxj/eZDRMSMjoxP0MTTcQ3vHT9Q0VVBaWcj+k8fZvDMcvy3BCM7uG3AP3E7ovkOcydRws/4aXb2PeGbsYGjUwKvfRhl/3svAkI6x58948XKQ4bEuWjuaudF0g+zSAvx37sbZawNuKn+EWU6uzHf1wMM/gLCoCOITjpGqSSIjI4WsrDQyv08lLf0c51MSuKBJQpN+lvSMZBLPniBq/25iDx/AzduP2QuW8dkXX024jSUSUYpUFJGIIubm5iYDMDMz+0+DMDMTEEUJEqm5ya0mEEUpgkKwRBSEf5BJpCjllihkShRypSlXmuLf+V+13EKJVFAgNZNjIZEjt5AjE+UITn7zcPA+wAzfvcwIPMLHXzozSaLEWmmLlcLmLawVNkwSbbC3t2H2osnMXvAh06d/xHtWU7C3/YA/AXwlVCOs9PKPAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/854f9cc5a6ccea7d0e26aa41f98ff8d2/e4706/iSpy_DVR_Agent_09.avif 230w", "/en/static/854f9cc5a6ccea7d0e26aa41f98ff8d2/d1af7/iSpy_DVR_Agent_09.avif 460w", "/en/static/854f9cc5a6ccea7d0e26aa41f98ff8d2/7f308/iSpy_DVR_Agent_09.avif 920w", "/en/static/854f9cc5a6ccea7d0e26aa41f98ff8d2/ecfe0/iSpy_DVR_Agent_09.avif 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/854f9cc5a6ccea7d0e26aa41f98ff8d2/a0b58/iSpy_DVR_Agent_09.webp 230w", "/en/static/854f9cc5a6ccea7d0e26aa41f98ff8d2/bc10c/iSpy_DVR_Agent_09.webp 460w", "/en/static/854f9cc5a6ccea7d0e26aa41f98ff8d2/966d8/iSpy_DVR_Agent_09.webp 920w", "/en/static/854f9cc5a6ccea7d0e26aa41f98ff8d2/7ed23/iSpy_DVR_Agent_09.webp 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/854f9cc5a6ccea7d0e26aa41f98ff8d2/81c8e/iSpy_DVR_Agent_09.png 230w", "/en/static/854f9cc5a6ccea7d0e26aa41f98ff8d2/08a84/iSpy_DVR_Agent_09.png 460w", "/en/static/854f9cc5a6ccea7d0e26aa41f98ff8d2/c0255/iSpy_DVR_Agent_09.png 920w", "/en/static/854f9cc5a6ccea7d0e26aa41f98ff8d2/6bfd0/iSpy_DVR_Agent_09.png 1011w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/854f9cc5a6ccea7d0e26aa41f98ff8d2/c0255/iSpy_DVR_Agent_09.png",
              "alt": "iSpy Agent DVR in Docker",
              "title": "iSpy Agent DVR in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "adding-mqtt-events",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#adding-mqtt-events",
        "aria-label": "adding mqtt events permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding MQTT Events`}</h3>
    <p>{`The DVR Agent can be connected to an MQTT Broker in your network to update MQTT topics in case of a variety of events. One example is sending an alarm notification when motion was detected in your camera's live stream. You can activate this option in your camera configuration:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/504c19955c95def8a2edcd7d1c747a83/54bf4/iSpy_DVR_Agent_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.52173913043477%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7rAAAO6wFxzYGVAAADGUlEQVQoz1XP3U/bVhgG8CO1JYUFLdFsHBJin+PvY5uA4yR24nx4FAEJ+WihTHRRA61ppUpT11EVEm1ol7vYLkdFHGJCAqxsveifOBHGxX4X792j53nB1EQg8PAhRVE8z3Mcx7FsPE7H5uKxWIwYC4VC4VB4+usgQ8oFpqYL1Yi5jBUFa/Pg/r0JAABiUSaTTqUMXddLBbu+Xl6vVAp3ioVirmhXM+tvzbfN3Bur0izkstmiAyYDU4HARCwWo2kaQhifm3Pf/ND/+8ufHz+enJwMh8Orq6uL0ej68z+/t4/c3R93Xh18X3fpr4IAAGCl87ZdlGWZZVleEGA8lv7u9dpvF8W1mp21MqZp3cpaZiq9lMtbyVR6UX+82VAZDFaW6rXqhqpqEEKe52ma3nPdy4uL4dhoNBoOh33fv7q8/HBwoBtJGWOsKNutpjIng0elynq5cRsWRREhtLO72/W8brfreV6v1/M87/j4+PT09Kd37wzDUBVFU9WN7S1xVgBLxfLaalXBym0zQqjklHZare3/azab5XLZtm3TNBOJxKPVFRhmgJNfXVmuYIwRQizHsZBh1EWx+WGWRpGZmcgYRVEkSTIMY5qmJEkY4+W1FSYUB7m0821xGcs3YZ7nIQPzheLWs+bGkyePN5/WNrdq9XqtWm00Go7jJJNJTdMkSWIZPhIggTFv2VYByxhCqCgKQsh1Xb9/6vv+8M5gMLi+vj48PLTzecMwRFGEURh5QAAjkc1mbHnczN6RJInjeV4Qbi4v3CyCkOM4x3Fs29ZUVeOEyH0CiEiz0jlJkiGEkigiCPf29v769Ols8J/z83Ov5w0GZ/v7+0bKWNQXVRUvIJ669w2IU2wmaYqihBBSFIVmmJcvds+8bs/zfN/v9/u+73e73cFg8H7//byaSBkpfUGnw9Gbn2fDtK7poiAiFkkYCwz99Nnzzh/H7Z9/6Yy12+1Op3N0dNRqPc/lbDtnJxILZIAQKQgmHwSng0GapjmOY1jWicwUSjXB/RXieRYy0WiUIAiSJAmCiEajqqpqmiYIwtTEZCg4/S/fEejCaMCUoQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/504c19955c95def8a2edcd7d1c747a83/e4706/iSpy_DVR_Agent_10.avif 230w", "/en/static/504c19955c95def8a2edcd7d1c747a83/d1af7/iSpy_DVR_Agent_10.avif 460w", "/en/static/504c19955c95def8a2edcd7d1c747a83/7f308/iSpy_DVR_Agent_10.avif 920w", "/en/static/504c19955c95def8a2edcd7d1c747a83/651ce/iSpy_DVR_Agent_10.avif 1007w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/504c19955c95def8a2edcd7d1c747a83/a0b58/iSpy_DVR_Agent_10.webp 230w", "/en/static/504c19955c95def8a2edcd7d1c747a83/bc10c/iSpy_DVR_Agent_10.webp 460w", "/en/static/504c19955c95def8a2edcd7d1c747a83/966d8/iSpy_DVR_Agent_10.webp 920w", "/en/static/504c19955c95def8a2edcd7d1c747a83/aa5f8/iSpy_DVR_Agent_10.webp 1007w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/504c19955c95def8a2edcd7d1c747a83/81c8e/iSpy_DVR_Agent_10.png 230w", "/en/static/504c19955c95def8a2edcd7d1c747a83/08a84/iSpy_DVR_Agent_10.png 460w", "/en/static/504c19955c95def8a2edcd7d1c747a83/c0255/iSpy_DVR_Agent_10.png 920w", "/en/static/504c19955c95def8a2edcd7d1c747a83/54bf4/iSpy_DVR_Agent_10.png 1007w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/504c19955c95def8a2edcd7d1c747a83/c0255/iSpy_DVR_Agent_10.png",
              "alt": "iSpy Agent DVR in Docker",
              "title": "iSpy Agent DVR in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`But to be able to send updates we first have to connect the Agent to our MQTT Broker - for example the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
      }}>{`INSTAR MQTT Broker`}</a>{` on your camera. To do this first switch to the `}<strong parentName="p">{`Actions`}</strong>{` configuration tab (see image above) and add an `}<strong parentName="p">{`MQTT Action`}</strong>{` for your camera:`}</p>
    {/* /Erweitert/INSTAR_MQTT_Broker/ */}
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/99c8719c4a2a83ad8076ed39f7cb5703/73b94/iSpy_DVR_Agent_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.695652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABKUlEQVQY02XLT0vCUAAA8EcgZLj33tSay9z7t3++59zI2VDJMCrawUliBhExlA51CbrUzXOXoGN9hb5kEHSwfvcf0CCCECGsY4wx0hHECGEIka7rhmFov0paSYeVbVzDeAdXquVyGWEdFAoF1xG9/eggDqNu0O604jge9HtCCEII+8GZqBNz1r5fdT/T8VtrvphOsmQ4AhsA8KAjL/JoeDlW16fBtOk1Pd/z/5G+Um5LqcCirFgsbmkl0DBr4jANn9+P5qun5HWRvAQylEqqdVIp5iriSsdWru350sebEJyl2TGnoVFtWOYure1Rk3PG1zHOHcEmPTIf0KRvDk6i/G5pVygYnWePFkktUheOzW3B7T9TcE6ZUA79uDW/lmb+ANM8mF3dWLj+DQiJOy9mmIHTAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/99c8719c4a2a83ad8076ed39f7cb5703/e4706/iSpy_DVR_Agent_11.avif 230w", "/en/static/99c8719c4a2a83ad8076ed39f7cb5703/d1af7/iSpy_DVR_Agent_11.avif 460w", "/en/static/99c8719c4a2a83ad8076ed39f7cb5703/7f308/iSpy_DVR_Agent_11.avif 920w", "/en/static/99c8719c4a2a83ad8076ed39f7cb5703/f13f3/iSpy_DVR_Agent_11.avif 1004w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/99c8719c4a2a83ad8076ed39f7cb5703/a0b58/iSpy_DVR_Agent_11.webp 230w", "/en/static/99c8719c4a2a83ad8076ed39f7cb5703/bc10c/iSpy_DVR_Agent_11.webp 460w", "/en/static/99c8719c4a2a83ad8076ed39f7cb5703/966d8/iSpy_DVR_Agent_11.webp 920w", "/en/static/99c8719c4a2a83ad8076ed39f7cb5703/22cc9/iSpy_DVR_Agent_11.webp 1004w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/99c8719c4a2a83ad8076ed39f7cb5703/81c8e/iSpy_DVR_Agent_11.png 230w", "/en/static/99c8719c4a2a83ad8076ed39f7cb5703/08a84/iSpy_DVR_Agent_11.png 460w", "/en/static/99c8719c4a2a83ad8076ed39f7cb5703/c0255/iSpy_DVR_Agent_11.png 920w", "/en/static/99c8719c4a2a83ad8076ed39f7cb5703/73b94/iSpy_DVR_Agent_11.png 1004w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/99c8719c4a2a83ad8076ed39f7cb5703/c0255/iSpy_DVR_Agent_11.png",
              "alt": "iSpy Agent DVR in Docker",
              "title": "iSpy Agent DVR in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the following example we tell the Agent to update an MQTT Topic in case of an alert. And to use a base64 encoded snapshot of your camera as payload - this image can be embedded in web interfaces (e.g. the dashboard from your smarthome) so every time an alert is triggered a fresh snapshot from your camera is displayed:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2288e382cb8411bbe3f328e861b7f3c0/46e51/iSpy_DVR_Agent_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB7UlEQVQoz23RXW/SYBTA8UKytd5gTCYdsFKep32eHlo7KCtvyzbHLCiizAjxJV7ozUy88Wq+ML3CLNl26RfwdV4YywxQoRTMNH4wA1uyzPnLuf2fnOQw3NQUy3HRqEgmKCUII57ng8FgOBwOhUL8iVn+4sysBOFs8RzHsizL+Px+v8+nqmrmWNpcSIHyX0BlKZ5epMWa3zfuGG6CKkrSMHT9kq7retIw0pmkYSSPJRKJBMB4IQAAJViIBAIBdpplFvMry8urlFKEkCTJKCrM1x9nn72dN3NxhQLE4TRFgbiq1e7W4gJlylfX16t1AEAoJssEY7z5/EWn57ZarYMzbNv+4TjNZvP+wwfqHDDWlcqNyq1JjGRZRgg3Go3haNR3B+7A+2d6fffw1+/dvb3b9+7QMGWswvVrpcrR2eMY482XW1/t7+8+fv6w/+X9p/2DttN2em2n13F67a7jjX6+2dkpFC18IcasrZZLVvkkRmjr1et2p/Ot1XI9bzAZ1/M8b9jtD+3uYDj6s729WyhZ4nmBWcoVCpctQghCCGMsiqKu6+OPHTHNtGlm0qaRMqtrqSc3jXrVyFt6TJAjHM8sJLJLuRVN0yRJIhLRVA0AiExkWcISVoBSoIpCRAIbRcHemHv6aCZf4xEfi0wH/wITIavA8e321QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2288e382cb8411bbe3f328e861b7f3c0/e4706/iSpy_DVR_Agent_12.avif 230w", "/en/static/2288e382cb8411bbe3f328e861b7f3c0/d1af7/iSpy_DVR_Agent_12.avif 460w", "/en/static/2288e382cb8411bbe3f328e861b7f3c0/7f308/iSpy_DVR_Agent_12.avif 920w", "/en/static/2288e382cb8411bbe3f328e861b7f3c0/8cad5/iSpy_DVR_Agent_12.avif 1003w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2288e382cb8411bbe3f328e861b7f3c0/a0b58/iSpy_DVR_Agent_12.webp 230w", "/en/static/2288e382cb8411bbe3f328e861b7f3c0/bc10c/iSpy_DVR_Agent_12.webp 460w", "/en/static/2288e382cb8411bbe3f328e861b7f3c0/966d8/iSpy_DVR_Agent_12.webp 920w", "/en/static/2288e382cb8411bbe3f328e861b7f3c0/2f8da/iSpy_DVR_Agent_12.webp 1003w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2288e382cb8411bbe3f328e861b7f3c0/81c8e/iSpy_DVR_Agent_12.png 230w", "/en/static/2288e382cb8411bbe3f328e861b7f3c0/08a84/iSpy_DVR_Agent_12.png 460w", "/en/static/2288e382cb8411bbe3f328e861b7f3c0/c0255/iSpy_DVR_Agent_12.png 920w", "/en/static/2288e382cb8411bbe3f328e861b7f3c0/46e51/iSpy_DVR_Agent_12.png 1003w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2288e382cb8411bbe3f328e861b7f3c0/c0255/iSpy_DVR_Agent_12.png",
              "alt": "iSpy Agent DVR in Docker",
              "title": "iSpy Agent DVR in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now to adding your MQTT Broker - open the `}<strong parentName="p">{`Software Settings`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c55c9859c87adbf87bfb009ff4af2af2/18c13/iSpy_DVR_Agent_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACvklEQVQoz22RyU8TUQDGn5SlQJNp09KpnZn3Zt57M52202Uo3Qu0bGXfXEiAKKAowcoSNIoJCsQlCiEkJi4hIVi9mBjl5MXEiySSGKPBk5w0XvgzTPECib98+ZLv8Dt9oLKsrNJkslltdpa1Wm1Wq81ssZjNZoZhWJa12+0Mw5iPY7FYEEI8RMBgKAEAxKKRgTP9yVg4GY9qHlWmBcghsvxvUfkQSqmiKA2NjenmLDAUGU4UFYmI8yqiSoRCUxGJoogxIVSUMESiKGEJU0kSkQgLQdBoNJaWlADNG/BpAdXj84Xislev4rBbVRORkIdieJL1elyJmO7zEATtXo8/pMdDetyjBjNNGcmBQEumI9PYPdwaejMX2hrHj7oqatr6Gm9vRKZX6eDUFb0337K2Hlu8Ay/29XknV/SxRaU/h4ZGh92CCprqssl091wXObhr/bPI7OVAeOBC9Plu8sknZSk/UTPxNv3+VXx7VXo2MO5aeMdd3SobWzMNnB+kDgoaUtlEbftkl39vWfl6nfkwAuKDl9Nb3+JPP0tLL88mZjd7dtfrPs7SfM+odiNPLj2uGFpimtuy0AJBQ7I5Es/O9moHD/lfC5XfZ0v91dW4rpPUtkuJhoAcrlfb6t1Z3Zk+PaEtbeOpF+UjK6bWrnbI8KA2mglHm3Ldvp/3XXu37DszZi/hEeckghMLHJIgL3GQcA6H0HpOuJbnpjfYoWVGll0Oox2EA7GgXp/rkPeX4Y95606uzOuSEC48jAkp/IUJpYR3kI4xNP/aObVpGr5n4lmeLbaBSDCmBVJT3e7fD/D+zfIvM0ZNkZCECT4CwSLC/oiU6hCiWUc4w6kIswYbcFN/OJQIamoqSFJ+mPBBcsw74kMsOAnkiSIrQVmpKrICgcVhPUKJzEGRR4RH/1UPdYoRT5KdzlPTTmTh7MW2v84IqqlqRGAPAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c55c9859c87adbf87bfb009ff4af2af2/e4706/iSpy_DVR_Agent_13.avif 230w", "/en/static/c55c9859c87adbf87bfb009ff4af2af2/d1af7/iSpy_DVR_Agent_13.avif 460w", "/en/static/c55c9859c87adbf87bfb009ff4af2af2/7f308/iSpy_DVR_Agent_13.avif 920w", "/en/static/c55c9859c87adbf87bfb009ff4af2af2/407b6/iSpy_DVR_Agent_13.avif 1005w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c55c9859c87adbf87bfb009ff4af2af2/a0b58/iSpy_DVR_Agent_13.webp 230w", "/en/static/c55c9859c87adbf87bfb009ff4af2af2/bc10c/iSpy_DVR_Agent_13.webp 460w", "/en/static/c55c9859c87adbf87bfb009ff4af2af2/966d8/iSpy_DVR_Agent_13.webp 920w", "/en/static/c55c9859c87adbf87bfb009ff4af2af2/49597/iSpy_DVR_Agent_13.webp 1005w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c55c9859c87adbf87bfb009ff4af2af2/81c8e/iSpy_DVR_Agent_13.png 230w", "/en/static/c55c9859c87adbf87bfb009ff4af2af2/08a84/iSpy_DVR_Agent_13.png 460w", "/en/static/c55c9859c87adbf87bfb009ff4af2af2/c0255/iSpy_DVR_Agent_13.png 920w", "/en/static/c55c9859c87adbf87bfb009ff4af2af2/18c13/iSpy_DVR_Agent_13.png 1005w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c55c9859c87adbf87bfb009ff4af2af2/c0255/iSpy_DVR_Agent_13.png",
              "alt": "iSpy Agent DVR in Docker",
              "title": "iSpy Agent DVR in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Switch to the `}<strong parentName="p">{`MQTT Tab`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/128329fc54405cd94c20a1eebb518d68/18c13/iSpy_DVR_Agent_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAABqUlEQVQY01XOW2/SUAAH8MKSBUbUdoCiPaynPfZc2rWsg7ZA5VKwF8SNOHVxZg8+mRlJjG/Oy5uvvvkZbGkMhn1Csy4++Hv/X7jS9napVAIAYIyxqgIAqtWqIAg8z9fr9VqtJgjCrrB7m7+F7uqB/MIlJ6A/MwxDMw+4YnGrUChgrHZyhJB2uz2ZTMb/8Uf+IOovzrvv595bLX7p2Lbd7XM75Z1yuQwAkCQJ5pbL5Xq9zrJstVpluTRNrzZXl18+HbqW41oPpWalUtkqFjnX9jxvSChVFAUhpORUVZVlWZIkURTv5xqNBpSgP55Y1iFl2rPT50wi3HQ4m0ULxjRZhgghCKHb7R0dL2ZxPJ/PwzAMclEU9Xs9x7FNw2CMnb05JyLmxo/CKHjKmAYhvNlfvrv48ztLkl9pmt48T5Jks9l8/vrN9QbeaKyZrZOzU3QPcSMvCKZPKKWyLCsIyXuAxK/Mi+/4oEOJSgillBJKJfGB6gXDy5+tDz+01x+nUbjHN7lBb/rYjwn5F4YQtzqWH+umua/r+v41xpiiKBhj23GuyxibhEHzjvgXAoRtjSEaBR8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/128329fc54405cd94c20a1eebb518d68/e4706/iSpy_DVR_Agent_14.avif 230w", "/en/static/128329fc54405cd94c20a1eebb518d68/d1af7/iSpy_DVR_Agent_14.avif 460w", "/en/static/128329fc54405cd94c20a1eebb518d68/7f308/iSpy_DVR_Agent_14.avif 920w", "/en/static/128329fc54405cd94c20a1eebb518d68/407b6/iSpy_DVR_Agent_14.avif 1005w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/128329fc54405cd94c20a1eebb518d68/a0b58/iSpy_DVR_Agent_14.webp 230w", "/en/static/128329fc54405cd94c20a1eebb518d68/bc10c/iSpy_DVR_Agent_14.webp 460w", "/en/static/128329fc54405cd94c20a1eebb518d68/966d8/iSpy_DVR_Agent_14.webp 920w", "/en/static/128329fc54405cd94c20a1eebb518d68/49597/iSpy_DVR_Agent_14.webp 1005w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/128329fc54405cd94c20a1eebb518d68/81c8e/iSpy_DVR_Agent_14.png 230w", "/en/static/128329fc54405cd94c20a1eebb518d68/08a84/iSpy_DVR_Agent_14.png 460w", "/en/static/128329fc54405cd94c20a1eebb518d68/c0255/iSpy_DVR_Agent_14.png 920w", "/en/static/128329fc54405cd94c20a1eebb518d68/18c13/iSpy_DVR_Agent_14.png 1005w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/128329fc54405cd94c20a1eebb518d68/c0255/iSpy_DVR_Agent_14.png",
              "alt": "iSpy Agent DVR in Docker",
              "title": "iSpy Agent DVR in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And add the address and login from your camera's `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/MQTT/"
      }}>{`MQTT Broker`}</a>{`:`}</p>
    {/* /Web_User_Interface/1440p_Serie/Smarthome/MQTT/ */}
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/81018a3d1ce2a77473b23b542247a93c/f3abf/iSpy_DVR_Agent_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACOElEQVQoz03QzW/ScBzH8d6dMWyDjQH9UQpbJGNmIxvCFAxl0m500JY+/YASD8rC44zGh8TAol7YXDTePGFh/gEGCPwGbB78wwxdJHvlff0evh+M/zRiG4PDk+HhyYBtDBL1QfJkkD3/G0gUcIudAF6zyW422S0L+PIisMzjC/Mr7sUHcbLo2E5iUvYZL2eNckZZToJi5sWT/ShfscI3zkTJtF+8xxzdZQpzTGGOLtxhj+Yzx56I5sagKitSWpHFfC6bz2YUWZSlNIRqcHd7I2j1P7I+hpHDeokRN5/yGzfFuHWKv78bXcMghKoKZVmRDKIoCoKgabngw6B1CSybHZKY7/b/9H5fDnqTWah//fljE1MUJZ1O8zyX+i+ZTEqSFAgEAACEi8hA9WoyHt0yHo+HQ1SvNzCWZRmGoWlmhqZplmX9fj8AwOl0ZiAcjUZoOEQIXRoQQpPJVbPZxDRNUxVFvEUQhHw+HwqFcBwHOIAQIoR6vV6/3+8Zut0uQmh6rCqKwPM8x83iUilZknZ2dgAAJElyHNfpdNrttq7rPw2tVqvTuXj39j22d8CEqWgkRs0KU1GKjm9ubU1/JohUKtUx3Nzrun7xq63/aGvRCvYqxlQpukbRtb39WoypUXQ1Gn8dT0S2/DbcQZKkx+Px+XzrPp/X611dW1v1eFZwcslGOqwurFL5Wiqflspntdp5tfqlWD4rlk6PX34Lh+M224rb7SYIYrqcMZ6LcC7ZnR8OrN+fmyM5yz8coe5V7W8IRAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/81018a3d1ce2a77473b23b542247a93c/e4706/iSpy_DVR_Agent_15.avif 230w", "/en/static/81018a3d1ce2a77473b23b542247a93c/d1af7/iSpy_DVR_Agent_15.avif 460w", "/en/static/81018a3d1ce2a77473b23b542247a93c/7f308/iSpy_DVR_Agent_15.avif 920w", "/en/static/81018a3d1ce2a77473b23b542247a93c/e1c99/iSpy_DVR_Agent_15.avif 1380w", "/en/static/81018a3d1ce2a77473b23b542247a93c/88708/iSpy_DVR_Agent_15.avif 1474w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/81018a3d1ce2a77473b23b542247a93c/a0b58/iSpy_DVR_Agent_15.webp 230w", "/en/static/81018a3d1ce2a77473b23b542247a93c/bc10c/iSpy_DVR_Agent_15.webp 460w", "/en/static/81018a3d1ce2a77473b23b542247a93c/966d8/iSpy_DVR_Agent_15.webp 920w", "/en/static/81018a3d1ce2a77473b23b542247a93c/445df/iSpy_DVR_Agent_15.webp 1380w", "/en/static/81018a3d1ce2a77473b23b542247a93c/0b448/iSpy_DVR_Agent_15.webp 1474w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/81018a3d1ce2a77473b23b542247a93c/81c8e/iSpy_DVR_Agent_15.png 230w", "/en/static/81018a3d1ce2a77473b23b542247a93c/08a84/iSpy_DVR_Agent_15.png 460w", "/en/static/81018a3d1ce2a77473b23b542247a93c/c0255/iSpy_DVR_Agent_15.png 920w", "/en/static/81018a3d1ce2a77473b23b542247a93c/b1001/iSpy_DVR_Agent_15.png 1380w", "/en/static/81018a3d1ce2a77473b23b542247a93c/f3abf/iSpy_DVR_Agent_15.png 1474w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/81018a3d1ce2a77473b23b542247a93c/c0255/iSpy_DVR_Agent_15.png",
              "alt": "iSpy Agent DVR in Docker",
              "title": "iSpy Agent DVR in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now every time an alert is triggered you additionally receive an MQTT update on the configured topic and the base64 encoded snapshot of the potential intruder:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/67dd976915cad4759a591b18f2605ebd/161ec/iSpy_DVR_Agent_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.086956521739133%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAA60lEQVQI1x3DTSsEcRzA8V+Ii/I2XFx2dufJ/Jn/7GBnx9pmD7KtiKVkbUxT1nmNTTm4eXgJao9KSi7ioKSkXLyXr/Kpj6goR0WneJU+unbGfJzjxzmzS33shZyCypgan2RC5P+oCCMijIkgIlSNAi+3V7zttrlfayLV2jq6nGC7FYpmmZIVYpQCXC9mpb6FHzSY8xdZThJWWy02dtp0jrqkvYy99IDjbofLNOO86HAyPYNoHeAHMa4XYlo+lq0xbY3jKALl4jkWYb3J5mGPi5tr7p4eef565+f3g8/vV4YPQ7LBgHB7HyNq8AdElndNbp15tAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/67dd976915cad4759a591b18f2605ebd/e4706/iSpy_DVR_Agent_16.avif 230w", "/en/static/67dd976915cad4759a591b18f2605ebd/d1af7/iSpy_DVR_Agent_16.avif 460w", "/en/static/67dd976915cad4759a591b18f2605ebd/7f308/iSpy_DVR_Agent_16.avif 920w", "/en/static/67dd976915cad4759a591b18f2605ebd/e1c99/iSpy_DVR_Agent_16.avif 1380w", "/en/static/67dd976915cad4759a591b18f2605ebd/76ea5/iSpy_DVR_Agent_16.avif 1840w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/67dd976915cad4759a591b18f2605ebd/a0b58/iSpy_DVR_Agent_16.webp 230w", "/en/static/67dd976915cad4759a591b18f2605ebd/bc10c/iSpy_DVR_Agent_16.webp 460w", "/en/static/67dd976915cad4759a591b18f2605ebd/966d8/iSpy_DVR_Agent_16.webp 920w", "/en/static/67dd976915cad4759a591b18f2605ebd/445df/iSpy_DVR_Agent_16.webp 1380w", "/en/static/67dd976915cad4759a591b18f2605ebd/78de1/iSpy_DVR_Agent_16.webp 1840w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/67dd976915cad4759a591b18f2605ebd/81c8e/iSpy_DVR_Agent_16.png 230w", "/en/static/67dd976915cad4759a591b18f2605ebd/08a84/iSpy_DVR_Agent_16.png 460w", "/en/static/67dd976915cad4759a591b18f2605ebd/c0255/iSpy_DVR_Agent_16.png 920w", "/en/static/67dd976915cad4759a591b18f2605ebd/b1001/iSpy_DVR_Agent_16.png 1380w", "/en/static/67dd976915cad4759a591b18f2605ebd/161ec/iSpy_DVR_Agent_16.png 1840w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/67dd976915cad4759a591b18f2605ebd/c0255/iSpy_DVR_Agent_16.png",
              "alt": "iSpy Agent DVR in Docker",
              "title": "iSpy Agent DVR in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      